<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Without referring to tables of thermodynamic data, sort each the following compounds based
        on their standard molar entropy values,
        <stemble-latex content="$\text{S}^\circ\text{.}$" />
      </p>

      <drag-to-rank-input
        v-model="inputs.input1"
        :items="items"
        class="mb-0"
        style="width: 500px"
        prepend-text="Largest"
        append-text="Smallest"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import DragToRankInput from '../inputs/DragToRankInput';

export default {
  name: 'Question277',
  components: {
    DragToRankInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
      },
      items: ['$\\ce{CH3CH2OH(g)}$', '$\\ce{N2(g)}$', '$\\ce{H2O(l)}$', '$\\ce{O2(g)}$'],
    };
  },
};
</script>
